<template>
	<div class="home" v-if="shouldAnswer !== undefined">
		<AnswerPoints
			v-if="shouldAnswer"
			:backToAddPoints="() => (shouldAnswer = false)"
			:currentPoint="currentPoint"
			:next="next"
		/>
		<AddPoints
			v-else
			:addPointText="addPointText"
			:points="points"
			:removePoint="removePoint"
			:goAnswer="() => (shouldAnswer = true)"
		/>
	</div>
</template>

<script>
import AddPoints from "@/components/AddPoints.vue";
import AnswerPoints from "@/components/AnswerPoints.vue";
import { getDailyPoints, saveRes } from "@/firebase/firestore";
import { isAuthenticated } from "../firebase/auth";

export default {
	name: "Home",
	components: { AddPoints, AnswerPoints },
	data() {
		return {
			points: undefined,
			shouldAnswer: undefined,
			currentPoint: null,
		};
	},
	watch: {
		shouldAnswer(value) {
			if (value) {
				this.next();
			}
		},
	},
	created() {
		getDailyPoints().then((results) => {
			if (results) {
				this.points = results.sort((a, b) => a.created > b.created);
				this.shouldAnswer = true;
			} else this.shouldAnswer = false;
		});
	},
	methods: {
		addPointText(obj) {
			const points = this.points || [];
			points.push(obj);
			this.points = points;
		},
		removePoint(index) {
			this.points.splice(index, 1);
		},
		next(answer, point) {
			console.log(answer, point);
			// Save answer
			if (answer) {
				// set answered in points
				const index = this.points.findIndex((e) => e.id === point.id);

				this.points[index].isAnswered = true;

				// save res
				point.ref.update({ isAnswered: true });
				saveRes({ answer, point });
			}

			// Select to next point
			const sorted = this.points
				.filter((e) => !e.isAnswered)
				.sort(() => (Math.random() > 0.5 ? 1 : -1));
			if (!sorted.length) this.shouldAnswer = false;
			else this.currentPoint = sorted[0];
		},
	},
};
</script>

<style scoped>
.loading {
	color: white;
	font-size: 42px;
}
</style>
