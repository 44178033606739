import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

export default function init() {
	var firebaseConfig = {
		apiKey: "AIzaSyBkj8VqgZGAzIjaVFTEEB2uHDlKgEF_7J0",
		authDomain: "think-9ef13.firebaseapp.com",
		projectId: "think-9ef13",
		storageBucket: "think-9ef13.appspot.com",
		messagingSenderId: "433040829552",
		appId: "1:433040829552:web:6631aa6d06e9d9c5fc98b5",
		measurementId: "G-PEG6PYVEX5",
	};
	// Initialize Firebase
	if (!firebase.apps.length) {
		firebase.initializeApp(firebaseConfig);
		firebase.analytics();
	}
}
