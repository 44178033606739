<template>
	<div id="app">
		<div id="nav">
			<p class="title" @click="$route.name !== 'Home' && $router.push('/')">
				THINK
			</p>
			<!-- <router-link to="/">Home</router-link> |
			<router-link to="/about">About</router-link> -->
			<div v-if="isAuth !== undefined" class="authLink" @click="authAction">
				{{ isAuth ? "Logout" : "Sign in to save your history" }}
			</div>
		</div>
		<router-view v-if="ready" />
	</div>
</template>

<script>
import { auth } from "./firebase/auth";
export default {
	data() {
		return {
			ready: false,
			isAuth: undefined,
		};
	},
	created() {
		auth.onAuthStateChanged((user) => {
			if (user) {
				this.isAuth = !user.isAnonymous;
				this.ready = true;
			} else {
				auth.signInAnonymously().then(() => (this.ready = true));
				this.isAuth = false;
			}
		});
	},
	methods: {
		authAction() {
			if (this.isAuth) this.logout();
			else this.$router.push("/login");
		},
		logout() {
			auth.signOut().then(() => location.reload());
		},
	},
};
</script>

<style>
html {
	--black: hsl(220, 13%, 18%);
}
body {
	margin: 0;
}
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	background-color: var(--black);
	min-height: 100vh;
}

#nav {
	padding: 30px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#nav a {
	font-weight: bold;
	color: #2c3e50;
}

#nav a.router-link-exact-active {
	color: #42b983;
}

/* GLOBAL STYLE  */
button {
	padding: 12px 34px;
	color: white;
	background-color: var(--black);
	border: 2px solid white;
	font-size: 24px;
	margin: 40px 16px;
	cursor: pointer;
}
</style>
<style scoped>
p {
	color: white;
	font-size: 42px;
	text-align: left;
	margin: 0;
}
.title {
	cursor: pointer;
}
.authLink {
	color: rgba(255, 255, 255, 0.5);
	padding-bottom: 2px;
	border-bottom: 0.25px solid rgba(255, 255, 255, 0.5);
	cursor: pointer;
}
</style>
