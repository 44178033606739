<template>
	<div class="parent">
		<button @click="backToAddPoints" class="addMore">Add more points</button>
		<h1 v-if="currentPoint">{{ currentPoint.text }}</h1>
		<textarea placeholder="Write your answer here" autofocus v-model="answer" />
		<button
			@click="
				() => {
					next(answer, currentPoint);
					answer = '';
				}
			"
		>
			NEXT
		</button>
	</div>
</template>

<script>
export default {
	props: ["backToAddPoints", "currentPoint", "next"],
	data() {
		return {
			answer: "",
		};
	},
};
</script>

<style scoped>
.parent {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
h1 {
	color: white;
	font-size: 42px;
}
.addMore {
	margin: 0;
	margin-right: auto;
	margin-left: 40px;
}
textarea {
	background-color: transparent;
	border: 2px solid white;
	color: white;
	font-size: 18px;
	resize: none;
	width: 42%;
	height: 420px;
	outline: none;
	padding: 16px;
}
</style>
