<template>
	<div class="loginPage">
		<div class="login" @click="google">Google Signin</div>
		<div class="login" @click="github">Github Signin</div>
		<div class="login" @click="twitter">Twitter Signin</div>
		<p v-if="error">{error}</p>
	</div>
</template>

<script>
import { providerSignin, getRedirect } from "@/firebase/auth";

export default {
	data() {
		return {
			error: "",
		};
	},
	methods: {
		google() {
			providerSignin("google");
		},
		github() {
			providerSignin("github");
		},
		twitter() {
			providerSignin("twitter");
		},
	},
};
</script>

<style scoped>
.loginPage {
	margin-top: 84px;
}
.login {
	border: 2px solid white;
	height: 42px;
	width: 280px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 25px;
	margin: 21px auto;
	cursor: pointer;
}
p {
	color: crimson;
}
</style>
