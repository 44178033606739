<template>
	<div class="parent">
		<div class="title">
			What points or questions do you want to think about today ?
		</div>
		<input
			placeholder="question or point"
			v-model="pointText"
			@keyup.enter="add"
		/>
		<div>
			<button @click="add">Add +</button>
			<button v-if="isReady" @click="goAnswer">
				I'm ready
			</button>
		</div>
		<div v-if="points">
			<p
				v-for="(p, index) in points"
				:key="p.text"
				class="pointItem"
				@click="() => deleteP(p, index)"
			>
				{{ index + 1 }}. {{ p.text }} {{ p.isAnswered && "(answered)" }}
			</p>
		</div>
	</div>
</template>

<script>
import { getUserId } from "@/firebase/auth";
import { addPointTextToDB } from "@/firebase/firestore";

export default {
	props: ["addPointText", "points", "removePoint", "goAnswer"],
	data() {
		return {
			pointText: "",
		};
	},
	computed: {
		isReady() {
			return this.points && this.points.filter((e) => !e.isAnswered).length;
		},
	},
	methods: {
		add() {
			if (!this.pointText.trim()) return;
			const pointObj = {
				text: this.pointText.trim(),
				userId: getUserId(),
			};
			addPointTextToDB(pointObj).then((ref) =>
				this.addPointText({ ...pointObj, ref })
			);
			this.pointText = "";
		},
		deleteP(p, index) {
			p.ref.delete();
			this.removePoint(index);
		},
	},
};
</script>

<style scoped>
.parent {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.title {
	color: white;
	font-size: 42px;
	padding: 0 16px;
	margin-bottom: 40px;
}
input {
	width: 42%;
	padding: 16px 24px;
	outline: none;
	font-size: 24px;
	color: white;
	background-color: transparent;
	border: 2px solid white;
}

.pointItem {
	font-size: 24px;
	color: white;
}
.pointItem:hover {
	text-decoration: line-through;
	cursor: grab;
}
@media only screen and (max-width: 666px) {
	.title {
		font-size: 30px;
	}
	input {
		width: 70%;
	}
}
</style>
