import firebase from "firebase/app";
import "firebase/firestore";
import { getUserId } from "./auth";

export const db = firebase.firestore();
const pointsCol = db.collection("points");
const resCol = db.collection("res");

// DB Utils
function today() {
	const date = new Date();
	const day = date.getDate();
	const month = date.getMonth();
	const year = date.getFullYear();
	return `${day}-${month}-${year}`;
}
function docHandler(doc) {
	let res = doc.data();
	res.id = doc.id;
	res.ref = doc.ref;
	return res;
}
function docsHandler(docs) {
	if (docs.empty) return null;
	return docs.docs.map(docHandler);
}

// DB GETS
export async function getDailyPoints() {
	const userId = getUserId();
	return pointsCol
		.where("date", "==", today())
		.where("userId", "==", userId)
		.get()
		.then(docsHandler);
}

// DB ADD
export async function addPointTextToDB(obj) {
	obj = { ...obj, created: Date.now(), date: today() };
	return pointsCol.add(obj);
}
export async function saveRes(res) {
	const obj = {
		...res,
		created: Date.now(),
		date: today(),
		userId: getUserId(),
	};
	resCol.add(obj);
}
