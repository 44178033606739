import firebase from "firebase/app";
import "firebase/auth";
import init from "./init";
init();

export const auth = firebase.auth();
export const isAuthenticated = () => auth.currentUser != undefined;
export const getUserId = () => auth.currentUser.uid;

export async function providerSignin(type) {
	var provider;
	if (type === "google") provider = new firebase.auth.GoogleAuthProvider();
	else if (type === "twitter")
		provider = new firebase.auth.TwitterAuthProvider();
	else if (type === "github") provider = new firebase.auth.GithubAuthProvider();
	auth.signInWithRedirect(provider);
}

export async function getRedirect() {
	return auth
		.getRedirectResult()
		.then((result) => {
			if (result.user) {
				return true;
			} else return false;
		})
		.catch((error) => {
			console.log("redirect error:" + error);
		});
}
